import { useSelector } from 'react-redux';
import { selectApplications } from '~/modules/applications/redux/applications.selectors';
import { Table } from '~/common/components/Table/Table';
import {
  applicationTypes,
  applicationRequestStatuses,
  applicationTypesLabels,
  applicationRequestStatusesLabels,
} from '~/modules/applications/models/application.model';
import { Link } from '~/common/components/Link/Link';
import { applicationsRoutes } from '~/modules/applications/router/Router';
import dayjs from 'dayjs';
import { useLoadApplications, useWidthdrawApplicationRequest } from '~/modules/applications/models/application.hooks';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const appliedRoutes = {
  [applicationTypes.student]: applicationsRoutes.edit_student_application,
  [applicationTypes.ta]: applicationsRoutes.edit_ta_application,
  [applicationTypes.mentor]: applicationsRoutes.edit_mentor_application,
};

const acceptedStatuses = [
  applicationRequestStatuses.matched,
  applicationRequestStatuses.waived_fee,
  applicationRequestStatuses.paid,
  applicationRequestStatuses.accepted,
];

const paidStatuses = [
  applicationRequestStatuses.matched,
  applicationRequestStatuses.waived_fee,
  applicationRequestStatuses.paid,
];

export const ApplicationsBlock = () => {
  const applications = useSelector(selectApplications);
  const widthdraw = useWidthdrawApplicationRequest();
  const now = dayjs();
  useLoadApplications();

  // function passed into sort needs to return -1, 0, or 1 for comparison
  // substracting date objects accomplishes this
  const orderedAppsDesc = applications.sort((apl, apl2) => {
    return new Date(apl.application_date) - new Date(apl2.application_date);
  }).reverse();

  const rows = orderedAppsDesc.map(apl => {
    const applicationId = apl.mentor_application || apl.student_application || apl.ta_application;
    const deadline = apl.deadline_date;
    const updateApplicationDeadline = apl.deadline_application_update_date;
    const deadline_accepting_offer = apl.deadline_accepting_offer_end_date;
    const courseName = apl.course_name;
    const applicationType = apl.application_type;
    const startDate = apl.application_date;
    const status = apl.status;
    const isAfterApplicationDeadline = now > dayjs(deadline);
    const isAfterUpdateDeadline = now > dayjs(updateApplicationDeadline);
    const isAfterAcceptingOffer = now > dayjs(deadline_accepting_offer);

    let linkTo;
    let courseLink;

    if (status === applicationRequestStatuses.applied && !isAfterApplicationDeadline && !isAfterUpdateDeadline) {
      linkTo = appliedRoutes[applicationType] + `/${apl.course}/${applicationId}`;
      courseLink = <Link to={linkTo}>{courseName}</Link>;
    } else if (acceptedStatuses.includes(status) && apl.course_is_active) {
      linkTo = applicationsRoutes.course + `/${applicationId}?type=${applicationType}`;
      courseLink = <Link to={linkTo}>{courseName}</Link>;
    } else if (status === applicationRequestStatuses.declined) {
      if (now > dayjs(deadline) || isAfterUpdateDeadline) {
        courseLink = courseName;
      } else {
        linkTo = appliedRoutes[applicationType] + `/${apl.course}/${applicationId}`;
        courseLink = <Link to={linkTo}>{courseName}</Link>;
      }
    } else {
      courseLink = courseName;
    }

    const formattedDate = dayjs(startDate).utc().format('MM-DD-YYYY');

    const confirmCallback = () => {
      const doWithdraw = window.confirm(`Are you sure you want to delete your application for ${courseName}? You will lose all progress made so far, and this process is irreversible.`);
      if (doWithdraw) {
        widthdraw(applicationId, applicationType);
      }
    };

    const dontRenderConfirmLink =
      apl.status === applicationRequestStatuses.rejected ||
      apl.status === applicationRequestStatuses.declined ||
      apl.status === applicationRequestStatuses.dismissed ||
      apl.status === applicationRequestStatuses.approved ||
      (isAfterApplicationDeadline && apl.status === applicationRequestStatuses.applied) ||
      isAfterAcceptingOffer ||
      paidStatuses.includes(status);

    const confirmLink = dontRenderConfirmLink ? null : <Link onClick={confirmCallback}>Delete</Link>;
    return [
      courseLink,
      formattedDate,
      applicationTypesLabels[applicationType],
      applicationRequestStatusesLabels[apl.status],
      confirmLink,
    ];
  });
  return (
    <>
      {applications.length > 0 && (
        <div className="applications">
          <h1>Submitted Applications</h1>
          <Table headCells={['Course', 'Application Date', 'Role', 'Status', 'Withdraw?']} rows={rows} />
        </div>
      )}
    </>
  );
};
